<template>
  <div>
    <section class="content-header">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <div class="form-group">
            <label for="">Monto</label>
            <input
              type="number"
              class="form-control form-control-sm"
              id="monto"
              :class="$v.formAnti.monto.$invalid ? 'is-invalid' : 'is-valid'"
              v-model="formAnti.monto"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <div class="form-group">
            <label for="">Moneda</label>
            <select
              class="form-control form-control-sm"
              v-model="formAnti.tipo_moneda"
              :class="
                $v.formAnti.tipo_moneda.$invalid ? 'is-invalid' : 'is-valid'
              "
            >
              <option value="">Seleccione...</option>
              <option
                v-for="divisa in listasForms.divisas"
                :key="divisa.numeracion"
                :value="divisa.numeracion"
              >
                {{ divisa.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <div class="form-group">
            <label for="">Fecha</label>
            <input
              type="date"
              class="form-control form-control-sm"
              id="fecha"
              :class="$v.formAnti.fecha.$invalid ? 'is-invalid' : 'is-valid'"
              v-model="formAnti.fecha"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <label for="">Documento</label>
          <div class="form-group" v-if="!formAnti.link_documento">
            <input
              type="file"
              id="archivo-adjunto"
              class="form-control-file"
              accept="image/x-png,image/gif,image/jpeg,application/pdf,"
              @change="obtenerArchivo"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <div class="btn-group float-left">
            <button
              type="button"
              v-if="fileAdjunto"
              class="btn btn-secondary"
              @click="limpiarArchivo"
            >
              <i class="fas fa-eraser"></i>
            </button>
          </div>
          <div class="btn-group mr-5 ml-3">
            <button
              type="button"
              @click="save()"
              class="btn btn-primary"
              v-show="
                !$v.formAnti.$invalid &&
                  fileAdjunto &&
                  $store.getters.can('com.clientes.agregarAnticipo')
              "
            >
              <i class="fa fa-plus-circle"></i> Agregar
            </button>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row">
        <div class="col-md-12">
          <table
            id="docs"
            class="table table-bordered table-striped table-hover table-sm table-head-fixed text-nowrap"
          >
            <thead>
              <tr>
                <th class="text-center">Monto</th>
                <th class="text-center">Moneda</th>
                <th class="text-center">Fecha</th>
                <th class="text-center">Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in anticipos" :key="data.id">
                <td class="text-center">{{ data.valorMonto }}</td>
                <td class="text-center">{{ data.nMoneda }}</td>
                <td class="text-center">{{ data.fecha }}</td>
                <td class="text-center">
                  <a
                    target="_blank"
                    class="btn btn-info btn-sm"
                    v-if="data.link_documento"
                    :href="uri_docs + data.link_documento"
                  >
                    <i class="fa fa-download" title="Descargar Documento"></i>
                  </a>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="destroy(data.id)"
                    v-show="$store.getters.can('com.clientes.eliminarAnticipo')"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  name: "ClienteAnticipo",
  data() {
    return {
      idAnticipo: "",
      anticipos: {},
      formAnti: {
        monto: null,
        tipo_moneda: null,
        fecha: null,
        link_documento: null,
        estado: 1,
      },
      listasForms: {
        eventos_correos: [],
        divisas: [],
      },
      fileAdjunto: null,
      archivoValido: true,
      link_documento: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    formAnti: {
      monto: {
        required,
        numeric,
      },
      fecha: {
        required,
      },
      tipo_moneda: {
        required,
      },
    },
  },

  methods: {
    getAnticipos() {
      axios
        .get("/api/com/clientes/showAnticipos", {
          params: {
            cliente: this.$parent.form.id,
          },
        })
        .then((response) => {
          this.anticipos = response.data;
          for (let j = 0; j < this.anticipos.length; j++) {
            let valorMonto = this.anticipos[j].monto;

            this.anticipos[j].valorMonto = this.formatCurrency(
              "en-US",
              "USD",
              2,
              valorMonto
            );
          }
        });
    },

    getMonedas() {
      axios.get("/api/lista/134").then((response) => {
        this.listasForms.divisas = response.data;
      });
    },

    instancia(anticipos) {
      this.idAnticipo = anticipos;
    },

    limpiarArchivo() {
      this.fileAdjunto = null;
      document.getElementById("archivo-adjunto").value = "";
    },

    obtenerArchivo(e) {
      this.fileAdjunto = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileAdjunto = file;
        }
      } else {
        this.archivoValido = true;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1000000) {
        flag = false;
        this.limpiarArchivo();
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo, seleccione un archivo de nuevo...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    save() {
      this.cargando = true;
      let formData = new FormData();
      let me = this;
      formData.append("cliente_id", me.$parent.form.id);
      formData.append("monto", me.formAnti.monto);
      formData.append("tipo_moneda", me.formAnti.tipo_moneda);
      formData.append("fecha", me.formAnti.fecha);
      formData.append("link_documento", me.fileAdjunto);
      formData.append("estado", 1);
      axios
        .post("api/com/clientes/saveAnticipo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.getAnticipos();
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.limpiarArchivo();
          this.formAnti = {};
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Anticipo?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/com/clientes/Anticipo/" + id).then(() => {
            this.getAnticipos();
            this.$swal({
              icon: "success",
              title: "Se elimino el Anticipo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },
  },

  created() {
    this.getAnticipos();
    this.getMonedas();
  },
};
</script>
