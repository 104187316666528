<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Clientes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Clientes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-3 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Clientes</h5>
                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Documentos"
                      v-if="form.id"
                      >Documentos</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('com.clientes.gestionarLista')"
                  >
                    <a
                      class="nav-link"
                      id="tab-Correos"
                      data-toggle="tab"
                      href="#Correos"
                      v-if="form.id"
                      >Lista De Distribución</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('com.clientes.gestionarAnticipos')"
                  >
                    <a
                      class="nav-link"
                      id="tab-Anticipos"
                      data-toggle="tab"
                      href="#Anticipos"
                      v-if="form.id"
                      >Anticipos</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="nit">Numero Identificación</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="n_identificacion"
                                  v-model="form.n_identificacion"
                                  :class="
                                    $v.form.n_identificacion.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="razon_social">Razon Social</label>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  id="razon_social"
                                  v-model="form.razon_social"
                                  :class="
                                    $v.form.razon_social.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="linea_negocio_id"
                                  >Linea de Negocio</label
                                >
                                <v-select
                                  :class="
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : ''
                                  "
                                  v-model="linea_negocio"
                                  placeholder="Lineas de Negocio"
                                  label="nombre"
                                  :options="listasForms.lineas_negocio"
                                  :filterable="true"
                                  multiple
                                  @input="getSelectLineasNegocio()"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="representante"
                                  >Representante Legal</label
                                >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  id="representante"
                                  v-model="form.representante"
                                  :class="
                                    $v.form.representante.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="ciudad"
                                  >Ciudad Oficina Principal</label
                                >
                                <v-select
                                  :class="[
                                    $v.form.ciudad_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="ciudad"
                                  placeholder="Ciudad"
                                  label="nombre"
                                  class="form-control form-control-sm p-0"
                                  :options="listasForms.ciudades"
                                  @input="getSelectCiudades()"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="telefono">Teléfono</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="telefono"
                                  v-model="form.telefono"
                                  :class="
                                    $v.form.telefono.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="cel1">Celular</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="cel1"
                                  v-model="form.cel1"
                                  :class="
                                    $v.form.cel1.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="cel2">Celular 2</label>
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="cel2"
                                  v-model="form.cel2"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                  type="email"
                                  class="form-control form-control-sm"
                                  id="email"
                                  v-model="form.email"
                                  :class="
                                    $v.form.email.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="premisas">Promedio Premisas</label>
                                <select
                                  id="tipo_ruta"
                                  class="form-control form-control-sm col-md-7"
                                  v-model="form.promedio_premisa"
                                  :class="
                                    $v.form.promedio_premisa.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="prom in listasForms.promedio_premisas"
                                    :key="prom.numeracion"
                                    :value="prom.numeracion"
                                  >
                                    {{ prom.descripcion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="row">
                            <table
                              class="table table-bordered table-striped table-hover table-sm text-nowrap"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    Impuesto
                                    <v-select
                                      :class="
                                        $store.getters.getDarkMode
                                          ? 'dark-vselect'
                                          : ''
                                      "
                                      class="text-center"
                                      v-model="form.impuestos"
                                      placeholder="Impuesto"
                                      label="descripcion"
                                      :options="listasForms.impuestos"
                                      style="
                                        font-size: 15px;
                                        background-color: #fff;
                                      "
                                      @input="selectImpuesto()"
                                      multiple
                                    ></v-select>
                                  </th>
                                  <th>Valor</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="imp in form.impuestos" :key="imp.numeracion">
                                  <td>{{ imp.descripcion }}</td>
                                </tr>
                              </tbody>
                            </table>                                                  
                          </div> -->
                        </div>
                        <div class="col-md-3" v-if="form.id">
                          <label for="imagen">Foto Logo Cliente</label>
                          <div class="form-group" v-if="form.logo == null">
                            <input
                              type="file"
                              class="form-control-file"
                              accept="image/x-png, image/gif, image/jpeg"
                              @change="obtenerImagen"
                            />
                          </div>
                          <figure class="form-group" v-if="miniLogo">
                            <div class="button-container">
                              <img
                                :src="miniLogo"
                                alt="Logo"
                                width="236"
                                height="125"
                                class="rounded mx-auto d-block"
                              />
                              <a
                                @click="destroyImage()"
                                id="BotonEliminar"
                                v-if="form.logo"
                                href="#"
                              >
                                Eliminar
                                <i class="fas fa-window-close"></i>
                              </a>
                            </div>
                          </figure>
                          <div class="row">
                            <div class="col-md-9"></div>
                            <div class="col-md-3" v-if="archivoValido">
                              <button
                                type="button"
                                @click="saveImage()"
                                class="btn btn-success"
                                data-toggle="tooltip"
                                data-html="true"
                                title="Guardar Imagen"
                              >
                                <i class="fa fa-upload"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Documentos" v-if="form.id">
                    <ClienteDocumento v-if="form.id" />
                  </div>
                  <div class="tab-pane fade" id="Correos" v-if="form.id">
                    <ClienteListaCorreo
                      v-if="
                        form.id &&
                        $store.getters.can('com.clientes.gestionarLista')
                      "
                    />
                  </div>
                  <div class="tab-pane fade" id="Anticipos" v-if="form.id">
                    <ClienteAnticipo
                      v-if="
                        form.id &&
                        $store.getters.can('com.clientes.gestionarLista')
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="$store.getters.can('com.clientes.edit')"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import ClienteDocumento from "./ClienteDocumento";
import ClienteListaCorreo from "./ClienteListaCorreo";
import ClienteAnticipo from "./ClienteAnticipo";
import Loading from "../../../../components/Loading";

export default {
  name: "ClienteForm",
  components: {
    ClienteDocumento,
    ClienteListaCorreo,
    ClienteAnticipo,
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {},
      ciudad: {},
      linea_negocio: [],
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      metodo: "",
      error: "",
      listasForms: {
        lineas_negocio: [],
        ciudades: [],
        tipo_operacion: [],
        estados: [],
        tipo_contacto: [],
        tipo_det_contacto: [],
        promedio_premisas: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    form: {
      n_identificacion: {
        required,
        minLength: minLength(7),
      },
      razon_social: {
        required,
      },
      representante: {
        required,
      },
      ciudad_id: {
        required,
      },
      telefono: {
        required,
        minLength: minLength(7),
      },
      cel1: {
        required,
        minLength: minLength(10),
      },
      email: {
        required,
        email,
      },
      promedio_premisa: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.miniLogo = this.uri_docs + this.form.logo;
        this.metodo = "PUT";
        if (this.form.ciudad) {
          this.ciudad = this.form.ciudad.nombre;
        }
        if (this.form.lineas_negocios) {
          this.form.lineas_negocios.forEach((lineas_negocio) => {
            this.linea_negocio.push({
              id: lineas_negocio.id,
              nombre: lineas_negocio.nombre,
            });
          });
        }
      } else {
        this.metodo = "POST";
        this.form.estado = 2;
      }
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getCiudades() {
      axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    getPromedioPremisas() {
      axios.get("/api/lista/192").then((response) => {
        this.listasForms.promedio_premisas = response.data;
      });
    },

    selectImpuesto() {
      this.form.impuesto = this.form.impuestos.map((e) => e.id);
    },

    getSelectLineasNegocio() {
      this.form.linea_negocio_id = null;
      if (this.linea_negocio) {
        this.form.linea_negocio_id = this.linea_negocio.id;
      }
    },

    getSelectCiudades() {
      this.form.ciudad_id = null;
      if (this.ciudad) {
        this.form.ciudad_id = this.ciudad.id;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.lineas_negocios = this.linea_negocio;
        axios({
          method: this.metodo,
          url: "/api/com/clientes",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El cliente se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title:
                    "El numero de identificacion (NIT) ya se encuentra en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    /*  selectImpuesto() {
      this.form.impuesto = this.form.impuesto.map((e) => e.numeracion);
    }, */

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/com/clientes/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.logo = response.data.logo;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/com/clientes/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function (error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.logo = null;
        }
      });
    },

    back() {
      return this.$router.replace("/Com/Clientes");
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    await this.getLineasNegocio();
    await this.getCiudades();
    await this.getPromedioPremisas();
    this.cargando = false;
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
